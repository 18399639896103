
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/ja'
import 'vue2-datepicker/index.css'

@Component({
  components: {
    DatePicker,
  },
  props: {
    disabledDateFunc: {
      type: Function,
    },
  },
})
export default class extends Vue {
  @Prop({ required: true, type: String })
  private readonly value!: string

  @Prop({ type: Array, default: (): string[] => [] })
  private readonly errors!: string[]

  @Prop({ type: String, default: '' })
  private readonly placeholder!: string

  @Prop({ type: Array, default: (): string[] => [] })
  private readonly classes!: string[]

  @Prop({ type: String, default: 'YYYY-MM-DD' })
  private readonly dateFormat!: string

  @Prop({ type: Boolean, default: false })
  private readonly disabled!: boolean

  private date = ''
  private lang = {
    formatLocale: {
      firstDayOfWeek: 0,
    },
    monthBeforeYear: false,
  }

  @Watch('value')
  private onChange (): void {
    this.date = this.value
  }

  private input (): void {
    this.$emit('input', this.date)
  }

  private close (): void {
    this.$emit('close', true)
  }
}

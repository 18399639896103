import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _75841791 = () => interopDefault(import('../pages/billings/index.vue' /* webpackChunkName: "pages/billings/index" */))
const _6d3a17f4 = () => interopDefault(import('../pages/billings/index/pdf.vue' /* webpackChunkName: "pages/billings/index/pdf" */))
const _4849d777 = () => interopDefault(import('../pages/billings/index/pdf/index.vue' /* webpackChunkName: "pages/billings/index/pdf/index" */))
const _3d17e05c = () => interopDefault(import('../pages/customers/index.vue' /* webpackChunkName: "pages/customers/index" */))
const _fdf2095e = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _7170af36 = () => interopDefault(import('../pages/get-start.vue' /* webpackChunkName: "pages/get-start" */))
const _00e8e1b0 = () => interopDefault(import('../pages/get-start/index.vue' /* webpackChunkName: "pages/get-start/index" */))
const _2f21dc5a = () => interopDefault(import('../pages/get-start/complete.vue' /* webpackChunkName: "pages/get-start/complete" */))
const _627834db = () => interopDefault(import('../pages/signin/index.vue' /* webpackChunkName: "pages/signin/index" */))
const _3c289309 = () => interopDefault(import('../pages/bfw/get-start.vue' /* webpackChunkName: "pages/bfw/get-start" */))
const _bbda2268 = () => interopDefault(import('../pages/bfw/get-start/index.vue' /* webpackChunkName: "pages/bfw/get-start/index" */))
const _dffc40a2 = () => interopDefault(import('../pages/bfw/get-start/complete.vue' /* webpackChunkName: "pages/bfw/get-start/complete" */))
const _f68f04fa = () => interopDefault(import('../pages/dashboard/status.vue' /* webpackChunkName: "pages/dashboard/status" */))
const _3d6810e8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7cfe1866 = () => interopDefault(import('../pages/signin/refund/_uuid/index.vue' /* webpackChunkName: "pages/signin/refund/_uuid/index" */))
const _c8b66b8e = () => interopDefault(import('../pages/billings/_id.vue' /* webpackChunkName: "pages/billings/_id" */))
const _d7f0428c = () => interopDefault(import('../pages/customers/_id.vue' /* webpackChunkName: "pages/customers/_id" */))
const _2448a4d8 = () => interopDefault(import('../pages/customers/_id/account.vue' /* webpackChunkName: "pages/customers/_id/account" */))
const _024c295b = () => interopDefault(import('../pages/customers/_id/account/index.vue' /* webpackChunkName: "pages/customers/_id/account/index" */))
const _33c766c0 = () => interopDefault(import('../pages/customers/_id/account/complete.vue' /* webpackChunkName: "pages/customers/_id/account/complete" */))
const _b9616fee = () => interopDefault(import('../pages/customers/_id/account/confirm.vue' /* webpackChunkName: "pages/customers/_id/account/confirm" */))
const _46124791 = () => interopDefault(import('../pages/customers/_id/account/error.vue' /* webpackChunkName: "pages/customers/_id/account/error" */))
const _b531a17a = () => interopDefault(import('../pages/customers/_id/accounttransfer.vue' /* webpackChunkName: "pages/customers/_id/accounttransfer" */))
const _25d410f4 = () => interopDefault(import('../pages/customers/_id/accounttransfer/index.vue' /* webpackChunkName: "pages/customers/_id/accounttransfer/index" */))
const _5fed329e = () => interopDefault(import('../pages/customers/_id/accounttransfer/company.vue' /* webpackChunkName: "pages/customers/_id/accounttransfer/company" */))
const _73d33696 = () => interopDefault(import('../pages/customers/_id/accounttransfer/complete.vue' /* webpackChunkName: "pages/customers/_id/accounttransfer/complete" */))
const _02de3774 = () => interopDefault(import('../pages/customers/_id/accounttransfer/confirm.vue' /* webpackChunkName: "pages/customers/_id/accounttransfer/confirm" */))
const _30dc15bc = () => interopDefault(import('../pages/customers/_id/accounttransfer/error.vue' /* webpackChunkName: "pages/customers/_id/accounttransfer/error" */))
const _4abf2a21 = () => interopDefault(import('../pages/customers/_id/accounttransferhistory.vue' /* webpackChunkName: "pages/customers/_id/accounttransferhistory" */))
const _aa9c5838 = () => interopDefault(import('../pages/customers/_id/accounttransferhistory/index.vue' /* webpackChunkName: "pages/customers/_id/accounttransferhistory/index" */))
const _8b079dc6 = () => interopDefault(import('../pages/customers/_id/refund.vue' /* webpackChunkName: "pages/customers/_id/refund" */))
const _10b4ade0 = () => interopDefault(import('../pages/customers/_id/refund/index.vue' /* webpackChunkName: "pages/customers/_id/refund/index" */))
const _2ec64fca = () => interopDefault(import('../pages/customers/_id/refund/complete.vue' /* webpackChunkName: "pages/customers/_id/refund/complete" */))
const _547acc16 = () => interopDefault(import('../pages/customers/_id/refund/error.vue' /* webpackChunkName: "pages/customers/_id/refund/error" */))
const _ca1181f2 = () => interopDefault(import('../pages/customers/_id/refundhistory.vue' /* webpackChunkName: "pages/customers/_id/refundhistory" */))
const _7464244a = () => interopDefault(import('../pages/customers/_id/refundhistory/index.vue' /* webpackChunkName: "pages/customers/_id/refundhistory/index" */))
const _6eb0d8bc = () => interopDefault(import('../pages/refund/_uuid/index.vue' /* webpackChunkName: "pages/refund/_uuid/index" */))
const _0c9c6fbf = () => interopDefault(import('../pages/refund/_uuid/complete.vue' /* webpackChunkName: "pages/refund/_uuid/complete" */))
const _ed74b9ac = () => interopDefault(import('../pages/refund/_uuid/confirm.vue' /* webpackChunkName: "pages/refund/_uuid/confirm" */))
const _9b12121c = () => interopDefault(import('../pages/refund/_uuid/error.vue' /* webpackChunkName: "pages/refund/_uuid/error" */))
const _817aafae = () => interopDefault(import('../pages/refund/_uuid/register.vue' /* webpackChunkName: "pages/refund/_uuid/register" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/billings",
    component: _75841791,
    name: "billings",
    children: [{
      path: "pdf",
      component: _6d3a17f4,
      children: [{
        path: "",
        component: _4849d777,
        name: "billings-index-pdf"
      }]
    }]
  }, {
    path: "/customers",
    component: _3d17e05c,
    name: "customers"
  }, {
    path: "/dashboard",
    component: _fdf2095e,
    name: "dashboard"
  }, {
    path: "/get-start",
    component: _7170af36,
    children: [{
      path: "",
      component: _00e8e1b0,
      name: "get-start"
    }, {
      path: "complete",
      component: _2f21dc5a,
      name: "get-start-complete"
    }]
  }, {
    path: "/signin",
    component: _627834db,
    name: "signin"
  }, {
    path: "/bfw/get-start",
    component: _3c289309,
    children: [{
      path: "",
      component: _bbda2268,
      name: "bfw-get-start"
    }, {
      path: "complete",
      component: _dffc40a2,
      name: "bfw-get-start-complete"
    }]
  }, {
    path: "/dashboard/status",
    component: _f68f04fa,
    name: "dashboard-status"
  }, {
    path: "/",
    component: _3d6810e8,
    name: "index"
  }, {
    path: "/signin/refund/:uuid",
    component: _7cfe1866,
    name: "signin-refund-uuid"
  }, {
    path: "/billings/:id",
    component: _c8b66b8e,
    name: "billings-id"
  }, {
    path: "/customers/:id",
    component: _d7f0428c,
    name: "customers-id",
    children: [{
      path: "account",
      component: _2448a4d8,
      children: [{
        path: "",
        component: _024c295b,
        name: "customers-id-account"
      }, {
        path: "complete",
        component: _33c766c0,
        name: "customers-id-account-complete"
      }, {
        path: "confirm",
        component: _b9616fee,
        name: "customers-id-account-confirm"
      }, {
        path: "error",
        component: _46124791,
        name: "customers-id-account-error"
      }]
    }, {
      path: "accounttransfer",
      component: _b531a17a,
      children: [{
        path: "",
        component: _25d410f4,
        name: "customers-id-accounttransfer"
      }, {
        path: "company",
        component: _5fed329e,
        name: "customers-id-accounttransfer-company"
      }, {
        path: "complete",
        component: _73d33696,
        name: "customers-id-accounttransfer-complete"
      }, {
        path: "confirm",
        component: _02de3774,
        name: "customers-id-accounttransfer-confirm"
      }, {
        path: "error",
        component: _30dc15bc,
        name: "customers-id-accounttransfer-error"
      }]
    }, {
      path: "accounttransferhistory",
      component: _4abf2a21,
      children: [{
        path: "",
        component: _aa9c5838,
        name: "customers-id-accounttransferhistory"
      }]
    }, {
      path: "refund",
      component: _8b079dc6,
      children: [{
        path: "",
        component: _10b4ade0,
        name: "customers-id-refund"
      }, {
        path: "complete",
        component: _2ec64fca,
        name: "customers-id-refund-complete"
      }, {
        path: "error",
        component: _547acc16,
        name: "customers-id-refund-error"
      }]
    }, {
      path: "refundhistory",
      component: _ca1181f2,
      children: [{
        path: "",
        component: _7464244a,
        name: "customers-id-refundhistory"
      }]
    }]
  }, {
    path: "/refund/:uuid",
    component: _6eb0d8bc,
    name: "refund-uuid"
  }, {
    path: "/refund/:uuid?/complete",
    component: _0c9c6fbf,
    name: "refund-uuid-complete"
  }, {
    path: "/refund/:uuid?/confirm",
    component: _ed74b9ac,
    name: "refund-uuid-confirm"
  }, {
    path: "/refund/:uuid?/error",
    component: _9b12121c,
    name: "refund-uuid-error"
  }, {
    path: "/refund/:uuid?/register",
    component: _817aafae,
    name: "refund-uuid-register"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import IntrospectionResult from '../introspection-result'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: IntrospectionResult,
})

export default (): {} => {
  return {
    httpEndpoint: `http://${process.env.ATANI_ENDPOINT}/query`,
    cache: new InMemoryCache({ fragmentMatcher }),
  }
}

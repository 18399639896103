
import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  get isActive (): boolean {
    return this.$store.state.isDrawerOpened
  }

  private onClick (): void {
    this.$store.dispatch('toggleDrawer')
  }
}

import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { Context } from '@nuxt/types'
import IntrospectionResult from '../introspection-result'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: IntrospectionResult,
})

export default (context: Context): {} => {
  const host = context.req ? context.req.headers.host : window.location.host
  return {
    httpEndpoint: `https://${host}/query`,
    cache: new InMemoryCache({ fragmentMatcher }),
  }
}


import { Component, namespace, Vue } from 'nuxt-property-decorator'
import parseISO from 'date-fns/parseISO'
import { formatISO } from 'date-fns'
import InputField from '../form/input-field.vue'
import Modal from '~/components/layout/modal.vue'
import BtnSearch from '~/components/element/button/btn-search.vue'
import Duration from '~/components/form/duration.vue'

const store = namespace('billings-search')

@Component({
  components: {
    Modal,
    InputField,
    BtnSearch,
    Duration,
  },
})
export default class BillingsSearchModal extends Vue {
  @store.State
  private readonly modalStatus!: boolean

  @store.State
  private readonly dateFrom!: string

  @store.State
  private readonly dateTo!: string

  @store.State
  private readonly amountFrom?: number | null

  @store.State
  private readonly amountTo?: number | null

  @store.State
  private readonly senderName!: string

  @store.State
  private readonly durationType!: string

  @store.Mutation
  private readonly setModalStatus!: (s: boolean) => void

  @store.Mutation
  private readonly setDateFrom!: (d: string) => void

  @store.Mutation
  private readonly setDateTo!: (d: string) => void

  @store.Mutation
  private readonly setAmountFrom!: (a: number | null) => void

  @store.Mutation
  private readonly setAmountTo!: (a: number | null) => void

  @store.Mutation
  private readonly setSenderName!: (s: string) => void

  @store.Mutation
  private readonly setDurationType!: (s: string) => void

  private inputAmountFrom = ''
  private inputAmountTo = ''
  private inputSenderName = ''
  private inputDate = {
    from: '',
    to: '',
    type: '1',
  }

  mounted (): void {
    this.inputAmountFrom = this.amountFrom ? this.amountFrom + '' : ''
    this.inputAmountTo = this.amountTo ? this.amountTo + '' : ''
    const dateFrom = this.dateFrom ? formatISO(parseISO(this.dateFrom), { representation: 'date' }) : ''
    const dateTo = this.dateTo ? formatISO(parseISO(this.dateTo), { representation: 'date' }) : ''
    this.inputDate = {
      from: dateFrom,
      to: dateTo,
      type: this.durationType,
    }
    this.inputSenderName = this.senderName
  }

  get disabled (): boolean {
    return this.inputAmountFrom === this.amountFrom + '' && this.inputAmountTo === this.amountTo + '' &&
    this.inputDate.from === this.dateFrom && this.inputDate.to === this.dateTo &&
    this.inputSenderName === this.senderName && this.inputDate.type === this.durationType
  }

  private close (): void {
    this.setModalStatus(false)
    this.$nextTick(() => {
      this.inputAmountFrom = this.amountFrom ? this.amountFrom + '' : ''
      this.inputAmountTo = this.amountTo ? this.amountTo + '' : ''
      const dateFrom = this.dateFrom ? formatISO(parseISO(this.dateFrom), { representation: 'date' }) : ''
      const dateTo = this.dateTo ? formatISO(parseISO(this.dateTo), { representation: 'date' }) : ''
      this.inputDate = {
        from: dateFrom,
        to: dateTo,
        type: this.durationType,
      }
      this.inputSenderName = this.senderName
    })
  }

  private onClick (): void {
    this.setDateFrom(this.inputDate.from ? formatISO(parseISO(this.inputDate.from)) : '')
    this.setDateTo(this.inputDate.to ? formatISO(parseISO(this.inputDate.to)) : '')
    this.setDurationType(this.inputDate.type)
    this.setAmountFrom(this.inputAmountFrom ? parseInt(this.inputAmountFrom, 10) : null)
    this.setAmountTo(this.inputAmountTo ? parseInt(this.inputAmountTo, 10) : null)
    this.setSenderName(this.inputSenderName)
    this.close()
  }
}

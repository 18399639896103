
import {
  Component,
  State,
  Vue,
} from 'nuxt-property-decorator'
import Header from '~/components/layout/header.vue'
import Footer from '~/components/layout/footer.vue'
import Drawer from '~/components/layout/drawer.vue'
import BillingsSearchModal from '~/components/billings/search-modal.vue'

@Component({
  components: {
    'edain-header': Header,
    'edain-footer': Footer,
    Drawer,
    BillingsSearchModal,
  },
})
export default class extends Vue {
  @State
  private gaId!: string

  @State('isDrawerOpened')
  private readonly isDrawerActive!: boolean
}


import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'

import { Option } from './selectbox/interface'
import { isHTMLObject, isHTMLInputObject } from '~/util/vue-helper'

type SearchHandler = (t: string) => Option[]

@Component({
  props: {
    name: String,
    isInvalid: Boolean,
    id: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
})
export default class extends Vue {
  @Prop({ type: String, default: '' })
  private readonly placeholder!: string

  @Prop({ required: true })
  private readonly value!: Option | null

  @Prop({ type: Array, required: true })
  private readonly options!: Option[]

  // allow component to clear input value when the list of options is changed
  @Prop({ required: false })
  private readonly loading!: boolean

  @Prop({ default: false })
  private readonly disableSearch!: boolean

  @Prop({ default: 'text' })
  private readonly label!: string

  @Prop()
  private readonly searchHandler?: SearchHandler

  @Prop({ default: false })
  private readonly disableInput!: boolean

  @Prop({ default: null })
  private readonly emptyOption!: Option | null

  @Prop({ default: false })
  private readonly disabled!: boolean

  private target: Option | null = this.value
  private input = ''

  private focus = false
  private selected: number | null = null

  private visibleOptions: Option[] = this.options

  private readonly OPTION_HEIGHT = 40
  private readonly HEIGHT = 160
  private readonly VISIBLE_OPTIONS = Math.floor(this.HEIGHT / this.OPTION_HEIGHT)

  get isFace (): boolean {
    return !!this.target && (!this.focus || this.disableInput)
  }

  get faceLabel (): string {
    return this.target ? this.target[this.label] : ''
  }

  get noOptions (): boolean {
    if (this.emptyOption) {
      return false
    }

    return !this.options || this.options.length === 0
  }

  get selectedPosition (): number {
    if (this.selected === null) {
      return 0
    }

    return this.selected * this.OPTION_HEIGHT
  }

  private isSelected (i: number): boolean {
    if (this.selected !== 0 && !this.selected) {
      return false
    }

    const p = this.emptyOption ? this.selected - 1 : this.selected
    return p === i
  }

  private scrollTo (n: number): void {
    const list = this.$refs.list
    if (isHTMLObject(list)) {
      list.scrollTop = n
    }
  }

  private search (s: string): void {
    if (this.disableSearch) {
      return
    }

    if (!s) {
      this.visibleOptions = this.options
      return
    }

    if (this.searchHandler) {
      this.visibleOptions = this.searchHandler(s)
      return
    }

    this.visibleOptions = this.options.filter((o) => {
      return o.textKana.includes(s) || o.text.includes(s)
    })
  }

  private close (): void {
    this.focus = false
    this.selected = null
  }

  private deleyClose (): void {
    this.$nextTick(() => {
      this.close()

      if (this.disableInput) {
        if (isHTMLObject(this.$refs.root)) {
          this.$refs.root.blur()
        }
      } else {
        const input = this.$refs.input
        if (isHTMLObject(input)) {
          input.blur()
        }
      }
    })
  }

  private onFocus (): void {
    if (this.disabled) {
      return
    }
    if (this.focus) {
      return
    }

    this.focus = true
    this.visibleOptions = this.options
    if (!this.disableInput) {
      const input = this.$refs.input
      if (isHTMLObject(input)) {
        this.$nextTick(() => {
          input.focus()
        })
      }
    }
  }

  private onBlurRoot (): void {
    if (this.disableInput) {
      this.onBlur()
    }
  }

  private onBlur (): void {
    if (this.disabled) {
      return
    }
    this.input = ''
    this.search('')
    this.close()
    this.$nextTick(() => {
      this.$emit('blur')
    })
  }

  private onMouseEnter (i: number): void {
    this.selected = this.emptyOption ? i + 1 : i
  }

  private onKeyDown (): void {
    const limitPosition = this.emptyOption ? this.visibleOptions.length : this.visibleOptions.length - 1
    if (this.selected === limitPosition) {
      return
    }

    if (this.selected === null) {
      this.selected = 0
      this.scrollTo(0)
      return
    }

    this.selected++
    const y = this.selectedPosition - (this.VISIBLE_OPTIONS - 1) * this.OPTION_HEIGHT
    const list = this.$refs.list
    if (isHTMLObject(list) && list.scrollTop <= y) {
      this.scrollTo(y)
    }
  }

  private onKeyUp (): void {
    if (this.selected === 0) {
      this.selected = null
      return
    }

    if (this.selected === null) {
      this.selected = 0
      this.scrollTo(0)
      return
    }

    this.selected--
    const list = this.$refs.list
    if (isHTMLObject(list) && list.scrollTop >= this.selectedPosition) {
      this.scrollTo(this.selectedPosition)
    }
  }

  private onKeyEnter (e: Event): void {
    if (this.selected === null) {
      return
    }
    e.preventDefault()
    e.stopPropagation()
    const s = this.emptyOption ? this.selected - 1 : this.selected
    this.target = this.visibleOptions[s]
    this.deleyClose()
  }

  private onDown (t: Option): void {
    this.target = t
    this.deleyClose()
  }

  private onInput (e: Event): void {
    const t = e.target
    if (t !== null && isHTMLInputObject(t)) {
      this.selected = null
      this.search(t.value)
      this.$emit('search-change', t.value)
    }
  }

  @Watch('target')
  private onChange (): void {
    this.$emit('input', this.target)
  }

  private onClickCarot (): void {
    if (this.focus) {
      return
    }

    this.onFocus()
  }

  @Watch('value')
  private onChangeValue (): void {
    this.target = this.value
  }

  @Watch('options')
  private onChangeOptions (): void {
    this.visibleOptions = this.options
  }
}


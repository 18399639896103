
import { Component, Prop, Emit, Vue } from 'nuxt-property-decorator'
import Btn from '~/components/element/btn.vue'

@Component({
  components: {
    Btn,
  },
})
export default class extends Vue {
  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop({ default: false })
  private readonly loading!: boolean

  @Prop({ default: '' })
  private readonly bgColor!: string

  @Prop({ default: 'border border-line' })
  private readonly borderStyle!: string

  @Prop({ default: '' })
  private readonly textColor!: string

  @Prop({ default: '' })
  private readonly textStyle!: string

  @Prop({ default: '' })
  private readonly text!: string

  @Emit('click')
  private onClick (e: Event): Event {
    return e
  }
}


import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  @Prop({ default: 'right' })
  private direction!: 'right' | 'left' | 'top' | 'bottom'

  @Prop({ default: false })
  private fill!: boolean

  get arrowStyle (): string {
    return `arrow-${this.direction}`
  }
}


import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  @Prop({ default: false })
  private readonly isOpen!: boolean

  private onClose (): void {
    this.$emit('close')
  }
}


import { Component, Vue, State } from 'nuxt-property-decorator'
import LogoutItem from '~/components/element/logout-item.vue'
import Yajirushi from '~/components/element/icon/yajirushi.vue'

@Component({
  components: {
    LogoutItem,
    Yajirushi,
  },
})
export default class extends Vue {
  @State
  private readonly email!: string

  get isOpened (): boolean {
    return this.$store.state.isDrawerOpened
  }

  private onClick (): void {
    this.$store.dispatch('closeDrawer')
  }
}


import { Component, State, Vue } from 'nuxt-property-decorator'
import Footer from '~/components/layout/footer.vue'

@Component({
  components: {
    'edain-footer': Footer,
  },
})
export default class extends Vue {
  @State
  private gaId!: string
}

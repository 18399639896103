import Vue from 'vue'
import VueApollo from 'vue-apollo'
import 'cross-fetch/polyfill'
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'
import Cookie from 'universal-cookie'
import { InMemoryCache } from 'apollo-cache-inmemory'

Vue.use(VueApollo)

export default (ctx, inject) => {
  const providerOptions = { clients: {} }
  const { app, beforeNuxtRender, req } = ctx
  const AUTH_TOKEN_NAME = 'apollo-token'
  const COOKIE_ATTRIBUTES = {"expires":7,"path":"\u002F","secure":false}
  const AUTH_TYPE = 'Bearer '
  const cookies = new Cookie(req && req.headers.cookie)
  const onCacheInitStore = { }

  // Config

      const defaultTokenName = ''  || AUTH_TOKEN_NAME

      function defaultGetAuth () {
        const token = cookies.get(defaultTokenName)
        return token && defaultClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let defaultClientConfig

        defaultClientConfig = require('@/apollo/cache.ts')

        if ('default' in defaultClientConfig) {
          defaultClientConfig = defaultClientConfig.default
        }

        defaultClientConfig = defaultClientConfig(ctx)

      if (process.server) {
        onCacheInitStore['default'] = defaultClientConfig.onCacheInit
        defaultClientConfig.onCacheInit = null
      }

      const defaultValidateToken = () => true

      if (!defaultClientConfig.validateToken) {
        defaultClientConfig.validateToken = defaultValidateToken
      }

      const defaultCache = defaultClientConfig.cache
        ? defaultClientConfig.cache
        : new InMemoryCache(defaultClientConfig.inMemoryCacheOptions ? defaultClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        defaultCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.defaultClient : null)
      }

      if (!defaultClientConfig.getAuth) {
        defaultClientConfig.getAuth = defaultGetAuth
      }

      if (process.client && defaultClientConfig.browserHttpEndpoint) {
        defaultClientConfig.httpEndpoint = defaultClientConfig.browserHttpEndpoint
      }

      defaultClientConfig.ssr = !!process.server
      defaultClientConfig.cache = defaultCache
      defaultClientConfig.tokenName = defaultTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!defaultClientConfig.httpLinkOptions) {
          defaultClientConfig.httpLinkOptions = {}
        }
        if (!defaultClientConfig.httpLinkOptions.headers) {
          defaultClientConfig.httpLinkOptions.headers = {}
        }
        defaultClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let defaultApolloCreation = createApolloClient({
        ...defaultClientConfig
      })
      defaultApolloCreation.apolloClient.wsClient = defaultApolloCreation.wsClient

          providerOptions.defaultClient = defaultApolloCreation.apolloClient

      const secondaryTokenName = ''  || AUTH_TOKEN_NAME

      function secondaryGetAuth () {
        const token = cookies.get(secondaryTokenName)
        return token && secondaryClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let secondaryClientConfig

        secondaryClientConfig = require('@/apollo/secondary-cache.ts')

        if ('default' in secondaryClientConfig) {
          secondaryClientConfig = secondaryClientConfig.default
        }

        secondaryClientConfig = secondaryClientConfig(ctx)

      if (process.server) {
        onCacheInitStore['secondary'] = secondaryClientConfig.onCacheInit
        secondaryClientConfig.onCacheInit = null
      }

      const secondaryValidateToken = () => true

      if (!secondaryClientConfig.validateToken) {
        secondaryClientConfig.validateToken = secondaryValidateToken
      }

      const secondaryCache = secondaryClientConfig.cache
        ? secondaryClientConfig.cache
        : new InMemoryCache(secondaryClientConfig.inMemoryCacheOptions ? secondaryClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        secondaryCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.secondary : null)
      }

      if (!secondaryClientConfig.getAuth) {
        secondaryClientConfig.getAuth = secondaryGetAuth
      }

      if (process.client && secondaryClientConfig.browserHttpEndpoint) {
        secondaryClientConfig.httpEndpoint = secondaryClientConfig.browserHttpEndpoint
      }

      secondaryClientConfig.ssr = !!process.server
      secondaryClientConfig.cache = secondaryCache
      secondaryClientConfig.tokenName = secondaryTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!secondaryClientConfig.httpLinkOptions) {
          secondaryClientConfig.httpLinkOptions = {}
        }
        if (!secondaryClientConfig.httpLinkOptions.headers) {
          secondaryClientConfig.httpLinkOptions.headers = {}
        }
        secondaryClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let secondaryApolloCreation = createApolloClient({
        ...secondaryClientConfig
      })
      secondaryApolloCreation.apolloClient.wsClient = secondaryApolloCreation.wsClient

          providerOptions.clients.secondary = secondaryApolloCreation.apolloClient

      const ssrTokenName = ''  || AUTH_TOKEN_NAME

      function ssrGetAuth () {
        const token = cookies.get(ssrTokenName)
        return token && ssrClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
      }

      let ssrClientConfig

        ssrClientConfig = require('@/apollo/ssr-cache.ts')

        if ('default' in ssrClientConfig) {
          ssrClientConfig = ssrClientConfig.default
        }

        ssrClientConfig = ssrClientConfig(ctx)

      if (process.server) {
        onCacheInitStore['ssr'] = ssrClientConfig.onCacheInit
        ssrClientConfig.onCacheInit = null
      }

      const ssrValidateToken = () => true

      if (!ssrClientConfig.validateToken) {
        ssrClientConfig.validateToken = ssrValidateToken
      }

      const ssrCache = ssrClientConfig.cache
        ? ssrClientConfig.cache
        : new InMemoryCache(ssrClientConfig.inMemoryCacheOptions ? ssrClientConfig.inMemoryCacheOptions: undefined)

      if (!process.server) {
        ssrCache.restore(window.__NUXT__ && window.__NUXT__.apollo ? window.__NUXT__.apollo.ssr : null)
      }

      if (!ssrClientConfig.getAuth) {
        ssrClientConfig.getAuth = ssrGetAuth
      }

      if (process.client && ssrClientConfig.browserHttpEndpoint) {
        ssrClientConfig.httpEndpoint = ssrClientConfig.browserHttpEndpoint
      }

      ssrClientConfig.ssr = !!process.server
      ssrClientConfig.cache = ssrCache
      ssrClientConfig.tokenName = ssrTokenName

      // if ssr we'd still like to have our webclient's cookies
      if (process.server && req && req.headers && req.headers.cookie) {
        if (!ssrClientConfig.httpLinkOptions) {
          ssrClientConfig.httpLinkOptions = {}
        }
        if (!ssrClientConfig.httpLinkOptions.headers) {
          ssrClientConfig.httpLinkOptions.headers = {}
        }
        ssrClientConfig.httpLinkOptions.headers.cookie = req.headers.cookie
      }

      // Create apollo client
      let ssrApolloCreation = createApolloClient({
        ...ssrClientConfig
      })
      ssrApolloCreation.apolloClient.wsClient = ssrApolloCreation.wsClient

          providerOptions.clients.ssr = ssrApolloCreation.apolloClient

  const vueApolloOptions = Object.assign(providerOptions, {
      errorHandler (error) {
          console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
      }
  })

  const apolloProvider = new VueApollo(vueApolloOptions)
  // Allow access to the provider in the context
  app.apolloProvider = apolloProvider

  if (process.server) {
    const ApolloSSR = require('vue-apollo/ssr')
    beforeNuxtRender(({ nuxtState }) => {
      nuxtState.apollo = {}
      const states = ApolloSSR.getStates(apolloProvider)

      for (let clientName in states) {
        nuxtState.apollo[clientName] = Object.assign({}, states[clientName])
      }

      // Clear apollo client cache after each request
      // Issues: https://github.com/nuxt-community/apollo-module/issues/273
      //         https://github.com/nuxt-community/apollo-module/issues/251
      Object.keys(apolloProvider.clients).forEach(clientName => {
        const client = apolloProvider.clients[clientName]
        const onCacheInitKey = clientName === 'defaultClient' ? 'default' : clientName
        const onCacheInit = onCacheInitStore[onCacheInitKey]
        client.cache.reset()
        if (typeof onCacheInit === 'function') onCacheInit(client.cache)
      })
    })
  }

  inject('apolloHelpers', {
    onLogin: async (token, apolloClient = apolloProvider.defaultClient, cookieAttributes = COOKIE_ATTRIBUTES, skipResetStore = false) => {
      // Fallback for tokenExpires param
      if (typeof cookieAttributes === 'number') cookieAttributes = { expires: cookieAttributes }

      if (typeof cookieAttributes.expires === 'number') {
        cookieAttributes.expires = new Date(Date.now()+ 86400*1000*cookieAttributes.expires)
      }

      if (token) {
        cookies.set(AUTH_TOKEN_NAME, token, cookieAttributes)
      } else {
        cookies.remove(AUTH_TOKEN_NAME, cookieAttributes)
      }
      if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
      if (!skipResetStore) {
        try {
          await apolloClient.resetStore()
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('%cError on cache reset (setToken)', 'color: orange;', e.message)
        }
      }
    },
    onLogout: async (apolloClient = apolloProvider.defaultClient, skipResetStore = false) => {
      cookies.remove(AUTH_TOKEN_NAME, COOKIE_ATTRIBUTES)
      if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
      if (!skipResetStore) {
        try {
          await apolloClient.resetStore()
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
        }
      }
    },
    getToken: (tokenName = AUTH_TOKEN_NAME) => {
      return cookies.get(tokenName)
    }
  })
}

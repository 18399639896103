
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { isHTMLInputObject } from '~/util/vue-helper'
import TextConverter from '~/util/text-converter'

@Component({
  props: {
    type: {
      type: String,
      default: 'text',
    },
    id: {
      type: String,
      required: true,
    },
    placeholder: String,
    classes: {
      type: Array,
      default: (): string[] => [],
    },
    errorMessageClasses: {
      type: Array,
      default: (): string[] => ['text-sm', 'my-2', 'text-left', 'text-icon'],
    },
    suppress: {
      type: Boolean,
      default: true,
    },
  },
})
export default class extends Vue {
  @Prop({ required: true, type: String })
  private readonly value!: string

  @Prop({ type: Array, default: (): string[] => [] })
  private readonly errors!: string[]

  @Prop({ default: false })
  private readonly autoConvertHalfNumber!: boolean

  @Prop({ default: false })
  private readonly autoConvertHalfWidthKana!: boolean

  private input (e: Event): void {
    if (e.target === null) {
      return
    }

    if (!isHTMLInputObject(e.target)) {
      return
    }
    this.$emit('input', e.target.value, e)
  }

  private blur (e: Event): void {
    if (e.target === null) {
      return
    }

    if (!isHTMLInputObject(e.target)) {
      return
    }
    if (this.autoConvertHalfNumber) {
      this.convertToHalfNumber()
    }
    if (this.autoConvertHalfWidthKana) {
      this.convertToHalfKana()
    }
    this.$emit('blur', e.target.value, e)
  }

  private convertToHalfNumber (): void {
    if (!this.value) {
      return
    }

    const halfText = new TextConverter(this.value).toHalfNumber().execute()
    this.$emit('input', halfText)
  }

  private convertToHalfKana (): void {
    if (!this.value) {
      return
    }

    const halfKanaText = new TextConverter(this.value).doubleByte().toKatakana().toUpperCase().toHalfKana().execute()
    this.$emit('input', halfKanaText)
  }
}

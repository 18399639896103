import { MutationTree } from 'vuex/types/index'
import { GraphQLError } from 'graphql'
import { BankAccountRegisterState } from '~/types'
import { Option } from '~/components/form/selectbox/interface'

export const state = (): BankAccountRegisterState => ({
})

export const mutations: MutationTree<BankAccountRegisterState> = {
  setBank: (state: BankAccountRegisterState, bank: Option): void => {
    state.bank = bank
  },
  setBankBranch: (state: BankAccountRegisterState, bankBranch: Option): void => {
    state.bankBranch = bankBranch
  },
  setBankType: (state: BankAccountRegisterState, bankType: Option): void => {
    state.bankType = bankType
  },
  setAccountNumber: (state: BankAccountRegisterState, accountNumber: string): void => {
    state.accountNumber = accountNumber
  },
  setHolderName: (state: BankAccountRegisterState, holderName: string): void => {
    state.holderName = holderName.trim()
  },
  setReadableHolderName: (state: BankAccountRegisterState, s: string): void => {
    state.readableHolderName = s
  },
  setGraphQLErrors: (state: BankAccountRegisterState, errs: GraphQLError[]): void => {
    state.graphQLErrors = errs
  },
  reset: (state: BankAccountRegisterState): void => {
    state.bank = undefined
    state.bankBranch = undefined
    state.bankType = undefined
    state.accountNumber = undefined
    state.holderName = undefined
    state.readableHolderName = undefined
  },
}

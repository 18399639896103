import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '~/types'
import MeQuery from '~/queries/me.gql'
import { signOut } from '~/util/nuxt-helper'

export const state = (): RootState => ({
  ataniToken: '',
  isDrawerOpened: false,
  gaId: '',
  email: '',
  redirect: '',
})

export const mutations: MutationTree<RootState> = {
  setAtaniToken: (state: RootState, token: string) => {
    state.ataniToken = token
  },
  setDrawerOpened: (state: RootState, isOpen: boolean) => {
    state.isDrawerOpened = isOpen
  },
  setGaId: (state: RootState, id: string) => {
    state.gaId = id
  },
  setEmail: (state: RootState, email: string) => {
    state.email = email
  },
  setRedirect: (state: RootState, path: string) => {
    state.redirect = path
  },
}

export const actions: ActionTree<RootState, RootState> = {
  async nuxtServerInit ({ commit }): Promise<void> { // eslint-disable-line require-await
    commit('setGaId', this.app.$env.GA_ID)
  },
  setAtaniToken ({ commit }, { token }: {token: string}) {
    commit('setAtaniToken', token)
  },
  closeDrawer ({ commit }) {
    commit('setDrawerOpened', false)
  },
  openDrawer ({ commit }) {
    commit('setDrawerOpened', true)
  },
  toggleDrawer ({ state, commit }) {
    commit('setDrawerOpened', !state.isDrawerOpened)
  },
  setRedirect ({ commit }, path: string) {
    if (path.match(/\/customers\/[0-9A-Z]{4}-[0-9A-Z]{4}$/)) {
      commit('setRedirect', path)
    } else {
      commit('setRedirect', '')
    }
  },
  async getEmail ({ commit }): Promise<string> {
    if (this.state.email) {
      return this.state.email
    }

    if (this.app.apolloProvider) {
      try {
        const response = await this.app.apolloProvider.defaultClient.query({
          query: MeQuery,
        })
        commit('setEmail', response.data.me.email)
        return this.state.email
      } catch (error) {
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          console.error(error.message)
          return ''
        }

        if (error.networkError && error.networkError.statusCode === 401) {
          console.error('request not authorized')

          // If user already login -> try to logout
          if (this.state.ataniToken) {
            await signOut()
          }

          return ''
        }

        throw error
      }
    }
    return ''
  },
}

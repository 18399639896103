import { NuxtApp, Context } from '@nuxt/types/app'
import { ApolloError } from 'apollo-client'
import { getAuth } from 'firebase/auth'
import axios from 'axios'
import { FirebaseError } from 'firebase/app'

type ServerParseError = Error & {
  response: Response;
  statusCode: number;
  bodyText: string;
};

const NOT_FOUND = 'not found'
const BAD_REQUEST = 'bad request'
const INVALID_ARGUMENT = 'invalid argument'

export function isGraphError (ref: Error): ref is ApolloError {
  return (ref as ApolloError).graphQLErrors !== undefined
}

function isServerParseError (ref: Error): ref is ServerParseError {
  return (ref as ServerParseError).statusCode !== undefined
}

function isContext (ref: NuxtApp | Context): ref is Context {
  return (ref as Context).error !== undefined
}

export function isFirebaseError (ref: Error | unknown): ref is FirebaseError {
  return (ref as FirebaseError).code !== undefined
}

function showErrorPage (app: NuxtApp | Context, statusCode: number): void {
  if (isContext(app)) {
    app.error({
      statusCode,
    })
    return
  }

  app.$nuxt.error({
    statusCode,
  })
}

function isUnauthorized (e: ApolloError): boolean {
  if (!e.networkError || !isServerParseError(e.networkError)) {
    return false
  }
  if (e.networkError.statusCode === 401) {
    return true
  }
  return false
}

export function emitError (app: NuxtApp | Context, e: Error | unknown): void {
  const err = e as Error
  if (isGraphError(err)) {
    const is404 = err.graphQLErrors.find((error) => {
      return error.extensions && error.extensions.code === NOT_FOUND
    })
    if (is404) {
      showErrorPage(app, 404)
      return
    }

    const is400 = err.graphQLErrors.find((error) => {
      return error.extensions && (
        error.extensions.code === INVALID_ARGUMENT || error.extensions.code === BAD_REQUEST
      )
    })
    if (is400) {
      showErrorPage(app, 400)
      return
    }
    if (isUnauthorized(err)) {
      if (process.client) {
        location.href = '/get-start'
        return
      }
    }

    if (isContext(app) && app.app !== undefined && app.app.$sentry) {
      app.app.$sentry.captureException(e)
    } else if (!isContext(app) && app !== undefined && app.$sentry) {
      app.$sentry.captureException(e)
    }

    showErrorPage(app, 500)
  }
}

export function getUserAgent (context: Context): string {
  return process.server
    ? context.req.headers['user-agent'] as string
    : navigator.userAgent
}

export function redirect (context: Context, path: string): void {
  let p = path
  if (context.route.fullPath.includes('?')) {
    p += context.route.fullPath.substr(context.route.fullPath.indexOf('?'))
  }
  context.redirect(p)
}

export async function signOut (): Promise<void> {
  try {
    await getAuth().signOut()
    await axios.post('/api/signout')
    localStorage.removeItem('vuex-localStorage')
    localStorage.removeItem('alreadyShowBanner')
    localStorage.removeItem('recentSellerName')

    location.href = '/get-start'
  } catch (e) {
    console.log(e)
    throw e
  }
}

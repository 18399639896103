import createPersistedState from 'vuex-persistedstate'
import { Plugin } from '@nuxt/types'

const ls: Plugin = ({ store }) => {
  createPersistedState({
    key: 'vuex-localStorage',
    paths: [
      'email',
      'redirect',
    ],
  })(store)
}

export default ls

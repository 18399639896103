
export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }

const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: 'INTERFACE',
        name: 'Billing',
        possibleTypes: [
          {
            name: 'RestrictedMFKInvoice',
          },
          {
            name: 'MFKInvoice',
          },
          {
            name: 'MFKAccountTransferNotification',
          },
          {
            name: 'RestrictedMFKAccountTransferNotification',
          },
          {
            name: 'OtherCustomerMFKInvoice',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'PaymentEvent',
        possibleTypes: [
          {
            name: 'InvoicePaymentEvent',
          },
          {
            name: 'AccountTransferPaymentEvent',
          },
        ],
      },
    ],
  },
}

export default result

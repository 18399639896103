
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { NuxtError } from '@nuxt/types/app'
import { Context } from '@nuxt/types'
import Btn from '~/components/element/btn.vue'
import Yajirushi from '~/components/element/icon/yajirushi.vue'
import { isLoginLessAuthenticationPages } from '~/util/vue-helper'

@Component({
  components: {
    Btn,
    Yajirushi,
  },
})
export default class extends Vue {
  @Prop()
  private readonly error?: NuxtError

  layout (context: Context): string {
    if (isLoginLessAuthenticationPages(context.route.path)) {
      return 'form'
    }
    return 'default'
  }

  get isLoginLessPages (): boolean {
    return isLoginLessAuthenticationPages(this.$route.path)
  }

  get errorCode (): number {
    if (this.error) {
      return this.error.statusCode ? this.error.statusCode : 500
    }

    return 500
  }

  get is404 (): boolean {
    return this.errorCode === 404
  }

  get title (): string {
    if (this.errorCode === 404) {
      return '404 NOT FOUND'
    }

    return '500 INTERNAL SERVER ERROR'
  }

  get firstMessage (): string {
    if (this.errorCode === 404) {
      return 'お探しのページは見つかりませんでした'
    }

    return '一時的なエラーが発生いたしました'
  }

  private linkToDashboard (): void {
    this.$router.push('/dashboard')
  }

  private onClickContact (): void {
    window.open('https://mfkessai.co.jp/buyer/ask/', '_blank')
  }
}

import Task from './task'
import {
  upperToSpecialMap,
} from '~/types/text-code'

// 全角数字を半角数字にする
export default class ToHalfNumber implements Task {
  private text = ''

  public execute (text: string): string {
    this.text = text
    this.convertHalfNumber().convertSpecials()
    return this.text
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private convertHalfNumber () {
    // 0-9の数字 と ()
    this.text = this.text.replace(/[\uFF08-\uFF19]/g, (s): string => {
      return String.fromCharCode(s.charCodeAt(0) - 65248)
    })
    return this
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private convertSpecials () {
    for (let i = 0; i < this.text.length; i++) {
      const code = this.text.charCodeAt(i)

      if (code in upperToSpecialMap) {
        this.text = this.text.replace(new RegExp(this.text.charAt(i), 'g'), (): string => {
          return String.fromCharCode(upperToSpecialMap[code])
        })
      }
    }
    return this
  }
}

import Vue from 'vue'

let clickOutSide: EventListener

Vue.directive('click-outside', {
  bind (el, binding, vnode) {
    // remove event listene if exists
    document.body.removeEventListener('click', clickOutSide)
    if (!el || !binding || !vnode) {
      return
    }

    clickOutSide = function (event: MouseEvent): void {
      if (!event.target) {
        return
      }
      event.stopPropagation()
      if (!(el === event.target || el.contains(event.target as Node)) && typeof binding.value === 'function') {
        binding.value(event)
      }
    } as EventListener

    setTimeout(() => document.body.addEventListener('click', clickOutSide), 500)
  },
  unbind () {
    document.body.removeEventListener('click', clickOutSide)
  },
})


import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  @Prop({ default: false })
  private readonly disabled!: boolean

  @Prop({ default: false })
  private readonly loading!: boolean

  @Prop({ default: 'bg-transparent hover:bg-gray-100' })
  private readonly bgColor!: string

  @Prop({ default: 'border-teal-500 border-solid border' })
  private readonly borderStyle!: string

  @Prop({ default: 'text-teal-500' })
  private readonly textColor!: string

  @Prop({ default: 'font-medium' })
  private readonly textStyle!: string

  @Prop({ default: '' })
  private readonly styles!: string

  get classes (): string[] {
    const cs: string[] = [this.bgColor, this.textColor, this.textStyle, this.borderStyle, this.styles]
    if (this.disabled) {
      cs.push('opacity-50')
    }

    if (this.loading) {
      cs.push('is-loading')
    }

    return cs
  }

  @Emit('click')
  private onClick (e: Event): Event {
    return e
  }
}

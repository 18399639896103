import { MutationTree } from 'vuex/types/index'
import { SideBannerState } from '~/types'

export const state = (): SideBannerState => ({
  isPopupClosed: false,
  isPanelOpened: false,
  sellerName: undefined,
})

export const mutations: MutationTree<SideBannerState> = {
  setIsPopupClosed: (state: SideBannerState, isClosed: boolean) => {
    state.isPopupClosed = isClosed
  },
  setIsPanelOpened: (state: SideBannerState, isOpened: boolean) => {
    state.isPanelOpened = isOpened
  },
  setSellerName: (state: SideBannerState, sellerName: string) => {
    state.sellerName = sellerName
  },

  reset: (state: SideBannerState): void => {
    state.isPopupClosed = false
    state.isPanelOpened = false
    state.sellerName = undefined
  },
}

import { Context } from '@nuxt/types'
import { redirect } from '~/util/nuxt-helper'
import { isLoginLessAuthenticationPages } from '~/util/vue-helper'

function isSigninPages (path: string): boolean {
  return path.indexOf('/get-start') === 0 || path.indexOf('/signin') === 0 || path.indexOf('/logger') === 0 || path.indexOf('/bfw/get-start') === 0
}

export default function (context: Context): void {
  if (isLoginLessAuthenticationPages(context.route.path)) {
    // 通常のログインとは別の認証によるセッション管理をするためリダイレクト対象外
    return
  }

  const token = context.store.state.ataniToken
  if (!token) {
    if (isSigninPages(context.route.path)) {
      return
    }
    return redirect(context, '/get-start')
  }

  if (isSigninPages(context.route.path)) {
    return redirect(context, '/dashboard')
  }
}

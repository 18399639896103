import { Context } from '@nuxt/types'
import { AxiosError } from 'axios'

export default function (context: Context): void {
  if (context.route.fullPath.indexOf('/logger') === 0) {
    return
  }

  if (process.server) {
    // servermiddlewareのloggerでも計測しているので
    return
  }

  const logData = {
    url: context.route.fullPath,
  }

  context.app.$axios.post('/logger', logData).catch((e: AxiosError) => {
    console.log(`error: ${e}`)
  })
}

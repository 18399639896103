
import parseISO from 'date-fns/parseISO'
import { addWeeks, formatISO } from 'date-fns'
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import addDays from 'date-fns/addDays'
import startOfDay from 'date-fns/startOfDay'
import { Option } from './selectbox/interface'
import SelectBox from '~/components/form/elf-selectbox.vue'
import DatePicker from '~/components/form/date-picker.vue'

@Component({
  components: {
    SelectBox,
    'e-date-picker': DatePicker,
  },
})
export default class extends Vue {
  @Prop()
  private readonly name!: string

  @Prop()
  private readonly label!: string

  @Prop({ required: true })
  private readonly value!: { from: string; to: string; type: string }

  private durations: Option[] = [
    {
      id: '1',
      text: 'すべての期間',
      textKana: 'すべてのきかん',
    },
    {
      id: '2',
      text: '過去7日間',
      textKana: '',
    },
    {
      id: '3',
      text: '過去30日間',
      textKana: '',
    },
    {
      id: '4',
      text: '過去90日間',
      textKana: '',
    },
    {
      id: '5',
      text: '過去180日間',
      textKana: '',
    },
    {
      id: '99',
      text: 'カレンダーで期限を指定する',
      textKana: '',
    },
  ]

  private val: Option = this.durations.find(d => d.id === this.value.type) || this.durations[0]
  private from: string = this.value.type === '99' && this.value.from ? this.value.from : ''
  private to: string = this.value.type === '99' && this.value.to ? this.value.to : ''

  protected mounted (): void {
    this.$on('reset', () => {
      this.reset()
    })
  }

  get isCustom (): boolean {
    return this.val.id === '99'
  }

  @Watch('value')
  private changeSourceValue (): void {
    this.val = this.durations.find(d => d.id === this.value.type) || this.durations[0]
    this.from = this.value.type === '99' && this.value.from ? this.value.from : ''
    this.to = this.value.type === '99' && this.value.to ? this.value.to : ''
  }

  @Watch('from')
  @Watch('to')
  private onInput (): void {
    this.emit()
  }

  private onChange (o: Option): void {
    this.val = o
    if (!this.isCustom) {
      this.emit()
    }
  }

  private reset (): void {
    this.val = this.durations[0]
    this.emit()
  }

  private emit (): void {
    if (!this.val) { return }

    const res = {
      from: '',
      to: '',
      type: this.val.id,
    }

    const date = startOfDay(new Date())
    switch (this.val.id) {
      case '1':
        break
      case '2': {
        const from = addWeeks(date, -1)
        res.from = formatISO(from)
        res.to = formatISO(date)
        break
      }
      case '3': {
        const from = addDays(date, -30)
        res.from = formatISO(from)
        res.to = formatISO(date)
        break
      }
      case '4': {
        const from = addDays(date, -90)
        res.from = formatISO(from)
        res.to = formatISO(date)
        break
      }
      case '5': {
        const from = addDays(date, -180)
        res.from = formatISO(from)
        res.to = formatISO(date)
        break
      }
      case '99':
        res.from = this.from ? formatISO(parseISO(this.from), { representation: 'date' }) : ''
        res.to = this.to ? formatISO(parseISO(this.to), { representation: 'date' }) : ''
        break
    }

    this.$emit('input', res)
  }
}

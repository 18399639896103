
import { Component, Vue, State } from 'nuxt-property-decorator'
import { signOut as signOutUtil } from '~/util/nuxt-helper'

@Component
export default class extends Vue {
  @State
  private readonly ataniToken!: string

  private underSigningOut = false

  private async signOut (): Promise<void> {
    if (this.underSigningOut) { return } // if signout process has already executed, it would not do anything.
    this.underSigningOut = true
    await signOutUtil()
  }
}

import { MutationTree } from 'vuex/types/index'
import { GraphQLError } from 'graphql'
import { RefundBankAccountAndRequestReservationState } from '~/types'
import { BankAccountTypeOption, Option } from '~/components/form/selectbox/interface'

export const state = (): RefundBankAccountAndRequestReservationState => ({
})

export const mutations: MutationTree<RefundBankAccountAndRequestReservationState> = {
  setCustomerId: (state, customerId: string): void => {
    state.customerId = customerId
  },
  setBank: (state, bank: Option): void => {
    state.bank = bank
  },
  setBankBranch: (state, bankBranch: Option): void => {
    state.bankBranch = bankBranch
  },
  setBankType: (state, bankType: BankAccountTypeOption): void => {
    state.bankType = bankType
  },
  setAccountNumber: (state, accountNumber: string): void => {
    state.accountNumber = accountNumber
  },
  setHolderName: (state, holderName: string): void => {
    state.holderName = holderName.trim()
  },
  setReadableHolderName: (state, s: string): void => {
    state.readableHolderName = s
  },
  setApplicantName: (state, applicantName: string): void => {
    state.applicantName = applicantName
  },
  setApplicantEmail: (state, applicantEmail: string) => {
    state.applicantEmail = applicantEmail
  },
  setApplicantTel: (state, applicantTel: string): void => {
    state.applicantTel = applicantTel
  },
  setDepositAmount: (state, depositAmount: number): void => {
    state.depositAmount = depositAmount
  },
  setUnpaidInvoicesAmount: (state, unpaidInvoicesAmount: number): void => {
    state.unpaidInvoicesAmount = unpaidInvoicesAmount
  },
  setRefundFee: (state, refundFee: number): void => {
    state.refundFee = refundFee
  },
  setRefundAmount: (state, refundAmount: number): void => {
    state.refundAmount = refundAmount
  },
  setGraphQLErrors: (state, errs: GraphQLError[]): void => {
    state.graphQLErrors = errs
  },

  reset: (state): void => {
    delete state.customerId
    delete state.bank
    delete state.bankBranch
    delete state.bankType
    delete state.accountNumber
    delete state.holderName
    delete state.readableHolderName
    delete state.applicantName
    delete state.applicantEmail
    delete state.applicantTel
    delete state.depositAmount
    delete state.unpaidInvoicesAmount
    delete state.refundFee
    delete state.refundAmount
  },
}

import { Context } from '@nuxt/types'
import cookie from 'cookie'

export default function (context: Context): void {
  let token
  if (process.server && context.req && context.req.headers && context.req.headers.cookie) {
    token = cookie.parse(context.req.headers.cookie as string)['atani-session']
  }

  if (!token) {
    if (process.server) {
      context.store.commit('setAtaniToken', null)
    }
    return
  }

  context.store.commit('setAtaniToken', token)
}

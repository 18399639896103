import { format } from 'date-fns-tz'
import Vue from 'vue'

Vue.filter('comma', (num?: number | string) => {
  if (num !== 0 && !num) { return '' }
  const target: number = typeof num === 'string' ? parseFloat(num) : num
  return target.toLocaleString('ja-JP', { maximumFractionDigits: 4 })
})

Vue.filter('formatDate', (s: string) => {
  if (!s) {
    return ''
  }
  return format(new Date(s), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' })
})

Vue.filter('formatDateTime', (s: string) => {
  if (!s) {
    return ''
  }
  return format(new Date(s), 'yyyy/MM/dd HH:mm', { timeZone: 'Asia/Tokyo' })
})

Vue.filter('formatShortDate', (s: string) => {
  if (!s) {
    return ''
  }
  return format(new Date(s), 'M/dd', { timeZone: 'Asia/Tokyo' })
})

import { MutationTree } from 'vuex/types/index'
import { RefundRequestState } from '~/types'

export const state = (): RefundRequestState => ({
})

export const mutations: MutationTree<RefundRequestState> = {
  setRefundAmount: (state: RefundRequestState, refundAmount: number): void => {
    state.refundAmount = refundAmount
  },
  setExpectedRefundDate: (state: RefundRequestState, s: string): void => {
    state.expectedRefundDate = s
  },
  reset: (state: RefundRequestState): void => {
    state.refundAmount = undefined
    state.expectedRefundDate = undefined
  },
}

const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.ts')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['check-auth'] = require('../middleware/check-auth.ts')
middleware['check-auth'] = middleware['check-auth'].default || middleware['check-auth']

middleware['logger'] = require('../middleware/logger.ts')
middleware['logger'] = middleware['logger'].default || middleware['logger']

export default middleware

import Vue from 'vue'
import { AxiosError } from 'axios'

export function isVueObject (ref: Vue | Element | (Vue | Element)[] | undefined): ref is Vue {
  if (!ref) { return false }
  return (ref as Vue).$emit !== undefined
}

export function isHTMLInputObject (ref: EventTarget | undefined): ref is HTMLInputElement {
  if (!ref) { return false }
  return (ref as HTMLInputElement).value !== undefined
}

export function isAxiosError (ref: Error): ref is AxiosError {
  return (ref as AxiosError).isAxiosError !== undefined
}

export function isHTMLObject (ref: Vue | Element | (Vue | Element)[] | undefined): ref is HTMLElement {
  if (!ref) { return false }
  return (ref as HTMLElement).clientHeight !== undefined
}

export function isInputObject (ref: Vue | Element | (Vue | Element)[] | EventTarget | undefined): ref is HTMLInputElement {
  if (!ref) { return false }
  return (ref as HTMLInputElement).value !== undefined
}

/**
 * ログインなし認証ページ
 * e.g. 預かり金返金フォーム
 * @param path
 */
export function isLoginLessAuthenticationPages (path: string): boolean {
  return path.indexOf('/refund') === 0 || path.indexOf('/signin/refund') === 0
}

import { initializeApp, getApps, FirebaseApp } from 'firebase/app'
import { Plugin } from '@nuxt/types'

const func: Plugin = ({ app }, inject) => {
  let fireapp: FirebaseApp | null = null
  if (!getApps.length) {
    // noinspection SpellCheckingInspection
    fireapp = initializeApp({
      apiKey: app.$env.FB_API_KEY,
      authDomain: app.$env.FB_AUTH_DOMAIN,
      databaseURL: app.$env.FB_DATABASE_URL,
      projectId: app.$env.FB_PROJECT_ID,
      storageBucket: '',
      messagingSenderId: app.$env.FB_MESSAGING_SENDER_ID,
      appId: app.$env.FB_APP_ID,
    })
  }

  inject('firebase', fireapp)
}

export default func

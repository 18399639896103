import { MutationTree } from 'vuex/types/index'
import { AccountTransferRequestState } from '~/types'
import { Option } from '~/components/form/selectbox/interface'

export const state = (): AccountTransferRequestState => ({
})

export const mutations: MutationTree<AccountTransferRequestState> = {
  setBank: (state: AccountTransferRequestState, bank: Option): void => {
    state.bank = bank
  },
  setBankBranch: (state: AccountTransferRequestState, bankBranch: Option): void => {
    state.bankBranch = bankBranch
  },
  setAccountType: (state: AccountTransferRequestState, accountType: Option): void => {
    state.accountType = accountType
  },
  setAccountNumber: (state: AccountTransferRequestState, accountNumber: string): void => {
    state.accountNumber = accountNumber
  },
  setHolderName: (state: AccountTransferRequestState, holderName: string): void => {
    state.holderName = holderName
  },
  setZipCode: (state: AccountTransferRequestState, zipCode: string): void => {
    state.zipCode = zipCode
  },
  setPrefecture: (state: AccountTransferRequestState, prefecture: Option): void => {
    state.prefecture = prefecture
  },
  setAddress1: (state: AccountTransferRequestState, address1: string): void => {
    state.address1 = address1
  },
  setName: (state: AccountTransferRequestState, name: string): void => {
    state.name = name
  },
  setTel: (state: AccountTransferRequestState, tel: string): void => {
    state.tel = tel
  },
  reset: (state: AccountTransferRequestState): void => {
    state.bank = undefined
    state.bankBranch = undefined
    state.accountType = undefined
    state.accountNumber = undefined
    state.holderName = undefined
    state.zipCode = undefined
    state.prefecture = undefined
    state.address1 = undefined
    state.name = undefined
    state.tel = undefined
  },
}

import { MutationTree } from 'vuex/types/index'

interface BillingSearchState {
  modalStatus: boolean;
  dateFrom: string;
  dateTo: string;
  amountFrom: number | null;
  amountTo: number | null;
  senderName: string;
  durationType: string;
}

export const state = (): BillingSearchState => ({
  modalStatus: false,
  dateFrom: '',
  dateTo: '',
  amountFrom: null,
  amountTo: null,
  senderName: '',
  durationType: '1',
})

export const mutations: MutationTree<BillingSearchState> = {
  setModalStatus: (state: BillingSearchState, status: boolean): void => {
    state.modalStatus = status
  },
  setDateFrom: (state: BillingSearchState, dateFrom: string): void => {
    state.dateFrom = dateFrom
  },
  setDateTo: (state: BillingSearchState, dateTo: string): void => {
    state.dateTo = dateTo
  },
  setAmountFrom: (state: BillingSearchState, amountFrom: number | null): void => {
    state.amountFrom = amountFrom
  },
  setAmountTo: (state: BillingSearchState, amountTo: number | null): void => {
    state.amountTo = amountTo
  },
  setSenderName: (state: BillingSearchState, senderName: string): void => {
    state.senderName = senderName
  },
  setDurationType: (state: BillingSearchState, type: string): void => {
    state.durationType = type
  },
}

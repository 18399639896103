
import { Component, Vue } from 'nuxt-property-decorator'
import Hamburger from './hamburger.vue'
import Account from './account.vue'
import Yajirushi from '~/components/element/icon/yajirushi.vue'

@Component({
  components: {
    Hamburger,
    Account,
    Yajirushi,
  },
})
export default class extends Vue {
}

import { MutationTree } from 'vuex/types/index'
import { AlertState, Alert } from '~/types'

export const state = (): AlertState => ({
  messages: [],
})

export const mutations: MutationTree<AlertState> = {
  setMessage: (state: AlertState, message: Alert): void => {
    state.messages.push(message)
  },
  popMessage: (state: AlertState): void => {
    state.messages.pop()
  },
  shiftMessage: (state: AlertState): void => {
    state.messages.shift()
  },
  resetAlert: (state: AlertState): void => {
    state.messages = []
  },
}

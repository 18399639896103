
import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  @Prop({ type: Boolean, default: false })
  private readonly loginPage!: boolean

  get footerClasses (): string {
    if (this.loginPage) {
      return ''
    }

    return 'hidden md:block'
  }

  get footerHeightClass (): string {
    if (this.loginPage) {
      return 'footer-height-login'
    }

    return 'footer-height'
  }
}

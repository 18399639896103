
import { Component, Vue, Action, State } from 'nuxt-property-decorator'
import LogoutItem from '~/components/element/logout-item.vue'

@Component({
  components: {
    LogoutItem,
  },
})
export default class extends Vue {
  @Action
  private readonly getEmail!: () => Promise<string>

  @State
  private readonly email!: string

  @State
  private readonly ataniToken!: string

  protected mounted (): void {
    if (process.client && this.ataniToken) {
      this.getEmail()
    }
  }

  get isOpened (): boolean {
    return this.$store.state.isDrawerOpened
  }

  private onToggle (): void {
    this.$store.dispatch('toggleDrawer')
  }

  private onClose (): void {
    this.$store.dispatch('closeDrawer')
  }
}
